.div-scroll {
    position: absolute;
    overflow-y: scroll;
    top: 135.5px;
    bottom: -350px;
    padding-right: 10px;
}

.incimg {
    width: 100%!important;
    height: auto!important;
}

@media (max-width: 768px) {
    .div-scroll {
    position: relative;}
}
