.filtr {
	margin-top: 1rem;
}
#createIncident .row {
	margin-bottom: 1rem;
}
.addmarker {
	float: right;
}
.react-datepicker__input-container input {
	background-color: #000;
	color: #ECECEC;
}
