.table {
    margin-block: 0;
}
.menu {
    font-size: small;
    color: #FEEE03;
}
.first {
    margin-top: 1rem;
}
.locnav {
    margin-top: 1.3rem;
}
.use svg {
    cursor: pointer;
}
.use svg:hover {
    color: blue;
}